@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body.qr-code-scanner-active > #root {
  visibility: hidden;
}

:root {
  /* Default Theme */
  --repeatmd-brand-color: 43 95 106;
  --repeatmd-brand-tint: var(--repeatmd-brand-color) / 0.1;
}

.AffirmModal {
  margin-top: env(safe-area-inset-top);
}

.qr-code-scanner-frame {
  /* square is 278/278 so using 278/2 from center of screen */
  clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(50% - 139px) 100%,
    calc(50% - 139px) calc(50% - 139px),
    calc(50% + 139px) calc(50% - 139px),
    calc(50% + 139px) calc(50% + 139px),
    calc(50% - 139px) calc(50% + 139px),
    calc(50% - 139px) 100%,
    100% 100%,
    100% 0%
  );
}
@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modalSlideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-scale-in {
  animation: scaleIn 0.45s ease-in-out;
  transform-origin: center;
}
.fade-in {
  animation: fadeIn 0.45s ease-in-out;
}
.modal-slide-in {
  animation: modalSlideIn 0.15s ease-out;
}

.slide-indicator {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slide-indicator:after {
  content: "";
  background-color: #ffffff;
  opacity: 0.5;
  border-radius: 50%;
  height: 6px;
  margin: 0 4px;
  width: 6px;
}
.slide-indicator.active:after,
.slide-indicator:hover:after {
  background-color: #ffffff;
  opacity: 1;
  width: 8px;
  height: 8px;
}

#slide-container::-webkit-scrollbar {
  display: none;
}

.radio-button {
  @apply m-2.5 h-6 w-6 cursor-pointer rounded-xl border border-solid border-dark-grey bg-white text-brand-color ring-0 focus:ring-0 focus:ring-transparent;
}

.radio-button:checked:hover,
.radio-button:checked:focus,
.radio-button:checked,
.radio-button:focus {
  background-image: none;
  box-shadow: inset 0 0 0 3px white;
  border-color: rgb(175 178 179 / var(--tw-border-opacity));
}

@layer base {
  ::-webkit-search-cancel-button,
  ::-webkit-search-decoration,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    appearance: none;
  }
}
